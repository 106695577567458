import classnames from 'classnames';
import { useRef } from 'react';
import { useInView } from 'framer-motion';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './ContactFormButton.css';

export const ContactFormButton = ({ href = '/contact' }: { href?: string }) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(buttonRef);
  const label = (isInView && 'Contact me') || '@';

  return (
    <Box className={classnames('contact-form-button', Styles.block)}>
      <div ref={buttonRef}>
        <a
          href={href}
          className={classnames(Styles.button)}
          title="Contact me"
        >
          <Box className={Styles.text}>{label}</Box>
        </a>
      </div>
    </Box>
  );
};

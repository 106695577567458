import classnames from 'classnames';
import { type PropsWithChildren } from 'react';

import { Box } from '@zenobius/ui-web-components-box';
import { CmsContent } from '@zenobius/ui-web-cmscontent';

import * as Styles from './GlobalFooter.css';

export function GlobalFooter({
  className,
  isVisible,
  content,
  children,
  ...props
}: PropsWithChildren<{
  isVisible?: boolean;
  className?: string;
  content?: string;
}>) {
  return (
    <Box
      className={classnames(
        'global-footer',
        className,
        className,
        Styles.block,
      )}
      {...props}
      asChild
    >
      <footer>
        {content && (
          <CmsContent
            className={Styles.blurb}
            content={content}
          />
        )}
        <Box className={Styles.links}>{children}</Box>
      </footer>
    </Box>
  );
}

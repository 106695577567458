import {
  Links,
  Meta,
  Outlet,
  Scripts,
  isRouteErrorResponse,
  useRouteError,
  ScrollRestoration,
  useMatches,
  useLocation,
  useLoaderData,
  Link,
} from '@remix-run/react';
import classnames from 'classnames';
import { query, from, where, first } from 'linq-functional';
import { json } from '@remix-run/server-runtime';
import { AnimatePresence, motion } from 'framer-motion';

import { GlobalCss } from '@zenobius/ui-web-uikit/globals';
import * as Themes from '@zenobius/ui-web-uikit/themes';
import { LinkProvider } from '@zenobius/ui-web-components-link';
import { Site } from '@zenobius/ui-web-components-sitelayout';
import {
  ContactFormButton,
  GlobalFooter,
  GlobalNav,
  NavigationProvider,
} from '@zenobius/ui-web-blog-components';
import { Box } from '@zenobius/ui-web-components-box';
import { Page } from '@zenobius/ui-web-components-page';
import {
  useClientSideFavicon,
  useClientSideFaviconColourStorage,
} from '@zenobius/ui-favicons';
import { MdxCollection } from '@zenobius/content';

import { useGetCurrentPathname } from './services/Navigation/useGetCurrentPathname';
import { useRouteHandles } from './services/routeHandles';
import '@zenobius/ui-web-uikit/fonts/robotoslab';

export async function loader() {
  try {
    const collection = await MdxCollection.Me;

    const footer = query(
      from(collection.items),
      where((item) => item.metadata.slug === 'me/footer'),
      first(),
    );

    return json({
      footer,
    });
  } catch (error) {
    throw new Error('Failed to load footer data');
  }
}

function LinkInterop({
  href,
  ...props
}: { href: string } & React.ComponentProps<'a'>) {
  return (
    <Link
      to={href}
      {...props}
    />
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  const matches = useMatches();
  const currentPath = matches.at(-1)?.pathname ?? '/';
  const data = useLoaderData<typeof loader>();
  const storage = useClientSideFaviconColourStorage();
  const handles = useRouteHandles();
  const location = useLocation();

  useClientSideFavicon(storage.value);

  return (
    <html
      lang="en"
      className={classnames(Themes.DefaultTheme, GlobalCss)}
    >
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <link
          rel="icon"
          href="/favicon-16.ico"
        />
        <link
          rel="icon"
          href="/favicon-32.ico"
          sizes="32x32"
        />
        <link
          rel="icon"
          href="/favicon.svg"
          type="image/svg+xml"
        />
        <link
          rel="apple-touch-icon"
          href="/apple-touch-icon.png"
        />
        <link
          rel="manifest"
          href="/manifest.webmanifest"
        />
        <Links />
      </head>
      <body>
        <LinkProvider component={LinkInterop}>
          <NavigationProvider currentPath={currentPath}>
            <Site pathId={location.pathname}>
              {handles.globalNav && (
                <Site.Header>
                  <GlobalNav
                    useLogo
                    currentPath={currentPath}
                  />
                </Site.Header>
              )}

              <AnimatePresence
                initial={false}
                mode="wait"
              >
                <Site.Main>{children}</Site.Main>
              </AnimatePresence>

              {handles.globalFooter && (
                <Site.Footer>
                  <GlobalFooter content={data.footer.content.compiled}>
                    <ContactFormButton />
                  </GlobalFooter>
                </Site.Footer>
              )}
            </Site>
          </NavigationProvider>
        </LinkProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

function isError(error: unknown): error is Error {
  return error instanceof Error;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const currentPath = useGetCurrentPathname();

  return (
    <>
      <Site.Header>
        <GlobalNav
          useLogo
          currentPath={currentPath}
        />
      </Site.Header>
      <Site.Main>
        <Page>
          {isRouteErrorResponse(error) && (
            <Page.Block direction="column">
              <Box
                asChild
                justifyContent="center"
              >
                <h1>
                  {error.status} {error.statusText}
                </h1>
              </Box>
              <Box asChild>
                <p>{error.data}</p>
              </Box>
            </Page.Block>
          )}
          {!isRouteErrorResponse(error) && (
            <Page.Block direction="column">
              <h1>Error!</h1>
              {isError(error) && <p>{error?.message ?? 'Unknown error'}</p>}
              {!isError(error) && <p>Unknown error</p>}
            </Page.Block>
          )}
        </Page>
      </Site.Main>
    </>
  );
}
